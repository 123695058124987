<template>
  <div>
<!--    style="background-color: #259558"-->
    <v-container fluid  class="pb-0">
      <v-row v-if="false">
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <v-card
                  flat
                  tile
                  color="#259558"
              >
                <v-row>

                  <v-col cols="12" lg="3" md="4" sm="6" v-if="isSimiaroom">
                    <v-card dark dense color="#259558" class="elevation-0">
                      <v-card-title class="subtitle-1 font-weight-bold">
                                                <span>
                                                    نیازمندی ها
                                                </span>
                      </v-card-title>
                      <v-card-text>
                        <v-list color="#259558">
                          <div v-for="(item,index) in simiaroom" :key="index"
                               class="footer-section-row caption">
                            <a class="footer-links" :href="item.href" target="_blank">{{ item.title }}</a>
                          </div>
                        </v-list>

                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6" v-if="isSimiaroom">
                    <v-card dark dense color="#259558" class="elevation-0">
                      <v-card-title class="subtitle-1 font-weight-bold">
                                                <span>
                                                    پر تکرار ها
                                                </span>
                      </v-card-title>
                      <v-card-text>
                        <v-list color="#259558">
                          <div v-for="(item,index) in issues" :key="index"
                               class="footer-section-row caption">
                            <a class="footer-links" :href="item.href" target="_blank">{{ item.title }}</a>
                          </div>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" lg="3" md="4" sm="6" v-if="!isSimiaroom">
                    <v-card-title class="white--text font-weight-light">
                      {{ aboutUsTitle }}
                    </v-card-title>
                    <v-card-text class="white--text pt-0 mt-0 font-weight-light">
                      <div class="mt-3">
                        {{ aboutUsText }}
                      </div>

                      <!--<div class="text-center ma-4" v-if="isSimiaroom">-->
                      <!--<a href="https://simiaroom.com/why-simiaroom-online-therapy/">-->
                      <!--<v-btn class="custom-gradient pa-6" text>-->
                      <!--<span class="light-green-button">-->
                      <!--چرا مشاوره آنلاین؟-->
                      <!--</span>-->
                      <!--</v-btn>-->
                      <!--</a>-->
                      <!--</div>-->
                    </v-card-text>

                  </v-col>


                  <!--<v-col cols="12" lg="3" md="4" sm="6" v-if="isSimiaroom">-->
                  <!--&lt;!&ndash;<v-card dark color="#259558" class="elevation-0">&ndash;&gt;-->
                  <!--&lt;!&ndash;<v-card-title class="subtitle-1 white&#45;&#45;text font-weight-bold">&ndash;&gt;-->
                  <!--&lt;!&ndash;<span>&ndash;&gt;-->
                  <!--&lt;!&ndash;شبکه های اجتماعی سیمیا&ndash;&gt;-->
                  <!--&lt;!&ndash;</span>&ndash;&gt;-->
                  <!--&lt;!&ndash;</v-card-title>&ndash;&gt;-->
                  <!--&lt;!&ndash;<v-card-text class="text-center">&ndash;&gt;-->
                  <!--&lt;!&ndash;<v-btn&ndash;&gt;-->
                  <!--&lt;!&ndash;v-for="(socialAccount,index) in socialAccounts"&ndash;&gt;-->
                  <!--&lt;!&ndash;:key="index"&ndash;&gt;-->
                  <!--&lt;!&ndash;class="mx-1 white&#45;&#45;text text-center justify-center"&ndash;&gt;-->
                  <!--&lt;!&ndash;:class="socialAccount.color"&ndash;&gt;-->
                  <!--&lt;!&ndash;:href="socialAccount.link"&ndash;&gt;-->
                  <!--&lt;!&ndash;target="_blank"&ndash;&gt;-->
                  <!--&lt;!&ndash;fab small&ndash;&gt;-->
                  <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;<v-icon>{{ socialAccount.icon }}</v-icon>&ndash;&gt;-->
                  <!--&lt;!&ndash;</v-btn>&ndash;&gt;-->
                  <!--&lt;!&ndash;</v-card-text>&ndash;&gt;-->
                  <!--&lt;!&ndash;</v-card>&ndash;&gt;-->
                  <!--</v-col>-->
                  <v-col cols="12" lg="3" md="4" sm="6" v-if="isSimiaroom">
                    <v-card-title class="white--text font-weight-light">تماس با سیمیا
                    </v-card-title>
                    <v-card-text class=" pt-0 mt-0 font-weight-light">
                      <div class="mt-3 footer-links">
                        <!--<v-icon class="mx-1">phone</v-icon>-->
                        <a href="tel:00982144057232" class="footer-link">
                          <span>شماره تماس: 00982144057232</span>
                        </a>

                      </div>

                      <div class="footer-links">
                        <!--<v-icon class="mx-1">email</v-icon>-->
                        <a href="mailto:COUNSELING@SIMIASERVICE.COM" class="footer-link">
                          <span>ایمیل: COUNSELING@SIMIASERVICE.COM</span>
                        </a>
                      </div>
                      <div class=" footer-links">
                        <!--<v-icon class="mx-1">fas fa-telegram</v-icon>-->
                        <a href="https://telegram.me/maryamkhazraee"
                           class="footer-link">
                          <span>پیام رسان تلگرام:@MARYAMKHAZRAEE</span>
                        </a>
                      </div>
                      <div class="footer-links">
                        <!--<v-icon class="mx-1">fas fa-whatsapp</v-icon>-->
                        <a href="http://wa.me/989302672054" class="footer-link">
                          <span>پیام رسان واتساپ: 00989302672054</span>
                        </a>


                      </div>
                    </v-card-text>
                    <v-card dark color="#259558" class="elevation-0">
                      <v-card-title class="white--text font-weight-bold mt-5">
                                                <span>
                                                    همراه ما باشید در شبکه های اجتماعی
                                                </span>
                      </v-card-title>
                      <v-card-text class="text-right mt-5">
                        <v-btn
                            text
                            v-for="(socialAccount,index) in socialAccounts"
                            :key="index"
                            class="mx-1 mt-1 white--text text-center justify-center"
                            :class="socialAccount.color"
                            :href="socialAccount.link"
                            target="_blank"
                            fab
                            x-small
                        >
                          <v-icon>{{ socialAccount.icon }}</v-icon>
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6" v-if="isSimiaroom">
                    <v-card dark color="#259558" class="elevation-0">
                      <v-card-title class="subtitle-1 white--text font-weight-bold">
                                                <span>
                                                    نماد اعتماد
                                                </span>
                      </v-card-title>
                      <v-card-text clas="mt-5">
                        <a referrerpolicy="origin" target="_blank"
                           href="https://simiaroom.com/enamad.html">
                          <img
                              referrerpolicy="origin"
                              src="/img/x2q.png"
                              alt="" style="cursor:pointer" id="wJP1koaRwQ4Q4E2nkKFp">
                        </a>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <!--<v-col cols="12" lg="3" md="3" sm="6">-->
                <!--<v-card-title class="white&#45;&#45;text font-weight-light">FAQ</v-card-title>-->
                <!--<v-card-text class="white&#45;&#45;text pt-0 mt-0 font-weight-light">-->
                <!--<div class="mt-3">-->
                <!--<div class="mt-3">How Can I Join?</div>-->
                <!--<div class="mt-3">Why Should I Join?</div>-->
                <!--<div class="mt-3">More...</div>-->
                <!--</div>-->
                <!--</v-card-text>-->
                <!--</v-col>-->

                <v-divider></v-divider>
                <v-row class="pa-5" v-if="isSimiaroom">

                  <v-col cols="auto"><a class="white--text"
                                        style="text-decoration: none;font-size: .7em;"
                                        href="https://simiaroom.com/%d8%b4%d8%b1%d8%a7%db%8c%d8%b7-%d8%b6%d9%88%d8%a7%d8%a8%d8%b7-%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87/">شرایط
                    و ضوابط مشاوره سیمیآروم</a></v-col>
                  <v-col cols="auto"><a class="white--text"
                                        style="text-decoration: none;font-size: .7em;"
                                        href="https://simiaroom.com/counselor/">مشاوران</a>
                  </v-col>
                  <v-col cols="auto"><a class="white--text"
                                        style="text-decoration: none;font-size: .7em;"
                                        href="https://simiaroom.com/%d8%af%d8%b1%d8%a8%d8%a7%d8%b1%d9%87-%d9%85%d8%a7/">درباره
                    ما</a></v-col>
                  <v-col cols="auto"><a class="white--text"
                                        style="text-decoration: none;font-size: .7em;"
                                        href="https://simiaroom.com/blog/">وبلاگ</a></v-col>

                  <v-col cols="auto"><a class="white--text"
                                        style="text-decoration: none;font-size: .7em;"
                                        href="https://simiaroom.com/%d8%aa%d9%85%d8%a7%d8%b3-2/">تماس
                    با ما</a></v-col>
                </v-row>

                <!--<v-card-text class="text-center">-->
                <!--<v-btn-->
                <!--v-for="icon in icons"-->
                <!--:key="icon"-->
                <!--class="mx-4 white&#45;&#45;text text-center justify-center"-->
                <!--icon-->
                <!--&gt;-->
                <!--<v-icon>{{ icon }}</v-icon>-->
                <!--</v-btn>-->
                <!--</v-card-text>-->


                <v-card-text class="white--text text-center">

                </v-card-text>
              </v-card>

            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-card class="elevation-0 py-2" tile color="#222" style="background-color: #1d242d;">
            <v-card-text class="text-right" style="color:rgba(255,255,255,.4);">
              <strong class="mx-1"> Copyright 2021 |
                <span v-if="isSimiaroom">Simiaroom |</span>
                All Rights Reserved</strong>
              <!--<div>-->
              <!--<span class="caption">{{ new Date().getFullYear() }}</span>-->
              <!--<span class="mx-1">—</span>-->
              <!--<v-icon small class="" color="#aaa">far fa-copyright</v-icon>-->
              <!--</div>-->

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
    <!--<v-card-->
    <!--flat-->
    <!--tile-->
    <!--dark-->
    <!--class=""-->
    <!--&gt;-->
    <!--<div class="pt-5 text-center">-->
    <!--در صورت بروز مشکل در فرایند ثبت سفارش، با پشتیبانی سیمیاروم در واتس اپ یا تلگرام به شماره-->
    <!--<v-btn rounded class="primary" small>-->
    <!--<span-->
    <!--style="display: inline-block;direction: ltr!important; text-align: left">-->
    <!--<a class="white&#45;&#45;text no-decoration"-->
    <!--href="whatsapp://send?phone=+989302672054">+989302672054</a>-->
    <!--</span>-->
    <!--</v-btn>-->
    <!--پیام ارسال فرمایید.-->
    <!--</div>-->

    <!--<v-card-text class="text-center">-->
    <!--<v-btn-->
    <!--v-for="(socialAccount,index) in socialAccounts"-->
    <!--:key="index"-->
    <!--class="mx-4 white&#45;&#45;text text-center justify-center"-->
    <!--socialAccount-->
    <!--:href="socialAccount.link"-->
    <!--target="_blank"-->
    <!--fab small-->
    <!--&gt;-->
    <!--<v-icon>{{ socialAccount.icon }}</v-icon>-->
    <!--</v-btn>-->
    <!--</v-card-text>-->


    <!--<v-divider></v-divider>-->

    <!--<v-card-text class="white&#45;&#45;text text-center">-->
    <!--{{ new Date().getFullYear() }}-->
    <!--<span class="mx-1">—</span>-->
    <!--<strong class="mx-1">مشاوره روانشناسی آنلاین</strong>-->
    <!--<v-icon small>far fa-copyright</v-icon>-->
    <!--</v-card-text>-->
    <!--</v-card>-->
  </div>
</template>
<script>
export default {
  props: {
    isSimiaroom: {
      default: true
    }
  },
  computed: {
    aboutUsTitle() {
      if (this.isSimiaroom) {
        return 'درباره سیمیاروم';
      } else {
        return 'درباره مشاور لایو';
      }


    },
    aboutUsText() {
      if (this.isSimiaroom) {
        return 'اگر از مشاوره روانشناسی آنلاین اطلاعات کمی دارید توصیه می کنیم راهنمای مشاوره های آنلاین روانشناسی را در لینک زیر مشاهده نمایید:';
      } else {
        return 'ارائه دهنده خدمات یکپارچه مشاوره ویدئویی برای روانشناسان و روانپزشکان';
      }
    }
  },
  data: () => ({


    simiaroom: [
      {
        title: 'تست اهمال کاری',
        href: 'https://simiaroom.com/campaign/%D8%AA%D8%B3%D8%AA-%D8%A7%D9%87%D9%85%D8%A7%D9%84-%DA%A9%D8%A7%D8%B1%DB%8C/',
      },
      {
        title: 'تست افسردگی',
        href: 'https://simiaroom.com/campaign/%D8%AA%D8%B3%D8%AA-%D8%A7%D9%81%D8%B3%D8%B1%D8%AF%DA%AF%DB%8C/',
      },
      {
        title: 'درمان افسردگی',
        href: 'https://simiaroom.com/counseling/%D8%AF%D8%B1%D9%85%D8%A7%D9%86-%D8%A7%D9%81%D8%B3%D8%B1%D8%AF%DA%AF%DB%8C/',
      },
      {
        title: 'درمان اضطراب',
        href: 'https://simiaroom.com/counseling/%D8%AF%D8%B1%D9%85%D8%A7%D9%86-%D8%A7%D8%B6%D8%B7%D8%B1%D8%A7%D8%A8/',
      },
      {
        title: 'درمان وسواس فکری',
        href: 'https://simiaroom.com/counseling/%D9%88%D8%B3%D9%88%D8%A7%D8%B3-%D9%81%DA%A9%D8%B1%DB%8C/',
      },
      {
        title: 'دانشنامه اختلالات سلامت روان',
        href: 'https://simiaroom.com/counseling/mental-disorders-encyclopedia/',
      },
      // {title: 'بلاگ', href: 'https://simiaroom.com/blog/'},
      // {title: 'مشاوران سیمیا', href: 'https://simiaroom.com/counselor/'},
      // {title: 'تماس با ما', href: 'https://simiaroom.com/%d8%aa%d9%85%d8%a7%d8%b3-2/'},
      // {title: 'درباره ما', href: 'https://simiaroom.com/%d8%af%d8%b1%d8%a8%d8%a7%d8%b1%d9%87-%d9%85%d8%a7/'},
    ],
    issues: [
      {
        title: 'مشاوره ازدواج',
        href: 'https://simiaroom.com/counseling/%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87-%d8%a7%d8%b2%d8%af%d9%88%d8%a7%d8%ac/'
      },
      {
        title: 'زوج درمانی',
        href: 'https://simiaroom.com/counseling/%d8%b2%d9%88%d8%ac-%d8%af%d8%b1%d9%85%d8%a7%d9%86%db%8c/'
      },
      {
        title: 'مشاوره جنسی',
        href: 'https://simiaroom.com/counseling/%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87-%d8%ac%d9%86%d8%b3%db%8c/'
      },
      {
        title: 'مشاوره فردی',
        href: 'https://simiaroom.com/counseling/%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87-%d9%81%d8%b1%d8%af%db%8c-%d8%a7%db%8c%d8%b1%d8%a7%d9%86%db%8c%d8%a7%d9%86-%d8%ae%d8%a7%d8%b1%d8%ac-%da%a9%d8%b4%d9%88%d8%b1/'
      },
      {
        title: 'مشاوره کودک و نوجوان',
        href: 'https://simiaroom.com/counseling/%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87-%da%a9%d9%88%d8%af%da%a9-%d9%86%d9%88%d8%ac%d9%88%d8%a7%d9%86-%d8%a7%db%8c%d8%b1%d8%a7%d9%86%db%8c%d8%a7%d9%86-%d8%ae%d8%a7%d8%b1%d8%ac-%da%a9%d8%b4%d9%88%d8%b1/'
      },
      {
        title: 'وسواس فکری عملی',
        href: 'https://simiaroom.com/counseling/%D9%88%D8%B3%D9%88%D8%A7%D8%B3-%D9%81%DA%A9%D8%B1%DB%8C/'
      },
      {
        title: 'مشاوره مهاجرت',
        href: 'https://simiaroom.com/counseling/%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87-%D9%85%D9%87%D8%A7%D8%AC%D8%B1%D8%AA/'
      },

      // {title: 'مشاوره خانواده', href: 'https://simiaroom.com/blog/category/family-counselling/'},
      // {
      //   title: 'زوج درمانی',
      //   href: 'https://simiaroom.com/counseling/%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87-%d8%a7%d8%b2%d8%af%d9%88%d8%a7%d8%ac/'
      // },
      // {title: 'مشاوره زناشویی', href: 'https://simiaroom.com/blog/category/marital-counseling/'},
      // {title: 'مشاوره فردی', href: 'https://simiaroom.com/blog/category/individual-counseling/'},
      // {
      //   title: 'مشاوره کودک و نوجوان',
      //   href: 'https://simiaroom.com/blog/category/child-and-adolescent-counselling/'
      // },
      // {title: 'حوزه های مشاوره', href: 'https://simiaroom.com/counseling/'},
    ],
    socialAccounts: [
      {
        icon: 'fab fa-facebook',
        link: 'https://www.facebook.com/simiatalk',
        color: 'blue darken-4'

      },
      {
        icon: 'fab fa-twitter',
        link: 'https://twitter.com/SimiaRoom',
        color: 'blue'

      },

      {
        icon: 'fab fa-linkedin',
        link: 'https://www.linkedin.com/company/simiaroom/',
        color: 'blue darken-2'

      },
      {
        icon: 'fab fa-instagram',
        link: 'https://www.instagram.com/simiaroomcom/',
        color: 'red lighten-1'
      },
      {
        icon: 'fab fa-youtube',
        link: 'https://www.youtube.com/channel/UCgM4KoG4MZg-ATBchGzQnAw/videos',
        color: 'red'
      },
    ],
  }),
}
</script>
<style>
.no-decoration {
  text-decoration: none !important;
}

.footer-links {
  color: #d3eade !important;
  text-decoration: none;
  font-weight: bold;
  padding: 15px 10px;
  margin: 0;
  display: inline-block;
  width: 100%;
  border-radius: 15px;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.footer-links:hover {
  background-color: white;
  color: #222 !important;
  box-shadow: 0 10px 10px -7px rgba(0, 0, 0, .3);
  /*padding-right: 15px;*/
}

.footer-section-row {

}

.footer-link {
  text-decoration: none;
  color: #d3eade !important;
  /*padding: 15px 0;*/
}

.footer-link:hover {
  color: #222 !important;
}
</style>