<template>
  <v-card-text>
    <v-alert
        v-if="payableFactor!=null && isLoggedIn"
        dismissible
        class="mb-1 "
        :class="{'payable-factor-preoder':true}"
        type="error"
        outlined
        elevation="1"
        icon="mdi-document"
    >
      <v-row dense>
        <v-col class="title primary--text" style="font-weight: lighter;">
          {{ $t('$vuetify.payableFactor.items[0]') }}
          <span class="font-weight-bold">{{ $t('$vuetify.payableFactor.items[1]') }}</span>
          {{ $t('$vuetify.payableFactor.items[2]') }}
          <span class="font-weight-bold">
            <template v-if="!$vuetify.rtl">
              {{ShowCurrencyByLang(payableFactor.currency_code)}}
            </template>
                        {{ `${payableFactor.remaining_amount} `}}
            <template v-if="$vuetify.rtl">
              {{ShowCurrencyByLang(payableFactor.currency_code)}}
            </template>
                    </span>
          {{ $t('$vuetify.payableFactor.items[3]') }}
        </v-col>
        <v-col :class=" checkLang == 'fa' ? 'text-left' :'text-right'">
          <v-btn rounded color="primary" class=""
                 @click="$router.push({name:'clientSingleFactor',params:{lang:$route.params.lang,factorId:payableFactor.id}})">
            <span class="mx-1" style="font-weight: bolder">{{ $t('$vuetify.payableFactor.action') }}</span>
            <v-icon x-small class="mx-1">
              {{ checkLang == 'fa' ? 'fas fa-long-arrow-alt-left' : 'fas fa-long-arrow-alt-right' }}
            </v-icon>
          </v-btn>
        </v-col>

      </v-row>
    </v-alert>
  </v-card-text>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters({
      'payableFactor': 'getPayableFactor',
      'isLoggedIn': 'userIsLoggedIn'
    }),
    checkLang() {
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.getPayableFactor();
    }
  },
  methods: {
    getPayableFactor() {
      this.$store.dispatch('getPayableFactor')
    },
    ShowCurrencyByLang(input) {
      // if (!this.$vuetify.rtl){
      //   if (input == "دلار"){
      //     return  "Dollar"
      //   }else{
      //     return "Tomans"
      //   }
      // }
      // return input;
      switch (input) {
        case "dollar":
          return this.$t('$vuetify.currency.dollar')
        case "rial":
          return this.$t('$vuetify.currency.rial');
        case "euro":
          return this.$t('$vuetify.currency.Euro')
      }
    }
  },
  props: {
    preorder: {
      default: false
    },
    factor: {
      default: () => {
        return null;
      }
    }
  },
  watch: {
    'isLoggedIn'(newState) {
      if (newState) {
        this.getPayableFactor();
      }
    }
  }

}
</script>

<style scoped>
.payable-factor-preoder {
  background-color: white !important
}
</style>