<template>
    <div>
        <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ currentTitle }}</span>
            <!--<v-avatar-->
            <!--color="primary lighten-2"-->
            <!--class="subheading white&#45;&#45;text"-->
            <!--size="24"-->
            <!--v-text="step"-->
            <!--&gt;</v-avatar>-->
        </v-card-title>

        <v-window v-model="step" :reverse="this.$store.getters.isRTL">
            <v-window-item :value="1">
                <v-form ref="registerForm" @keydown.enter.prevent='register'>
                    <v-card-text>
                        <v-text-field
                                @keydown.enter.prevent='register'
                                v-model="mutableEmail"
                                type="text"
                                :label="$vuetify.lang.t('$vuetify.emailLabel')"
                                required
                                :rules="emailRules">
                        </v-text-field>
                        <small class="grey--text text--darken-1">
                            {{$vuetify.lang.t('$vuetify.emailSignUpCaption')}}
                        </small>
                    </v-card-text>
                </v-form>
            </v-window-item>

            <v-window-item :value="2">

                <v-card-text>

                    <v-form ref="signupform" @keyup.native.enter="register">

                        <v-text-field
                                prepend-inner-icon="phone"
                                type="text"
                                v-model="phone"
                                :label="$vuetify.lang.t('$vuetify.phoneLabel')"
                                class="text-left"
                                style="text-align: left;direction: ltr"
                                placeholder="ex : +15551234567"
                        >
                        </v-text-field>

                        <v-text-field
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPass = !showPass"
                                :type="showPass ? 'text' : 'password'"
                                v-model="password"
                                :label="$vuetify.lang.t('$vuetify.passwordLabel')"
                                :rules="passwordRules"

                        ></v-text-field>
                        <v-text-field
                                :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showConfirmPass = !showConfirmPass"
                                :type="showConfirmPass ? 'text' : 'password'"
                                v-model="confirmPassword"
                                :label="$vuetify.lang.t('$vuetify.passwordConfirmationLabel')"
                                :rules="passwordConfirmRules"
                        ></v-text-field>
                        <span class="caption grey--text text--darken-1">
                        {{$vuetify.lang.t('$vuetify.passwordSignUpCaption')}}
                    </span>
                        <template v-if="inviter!=null">
                            <div class="px-2">
                                کد معرف شما :
                                <v-chip small class="blue white--text">{{inviter}}</v-chip>
                            </div>
                        </template>

                        <v-checkbox v-model="privacyPolicy" class="text-center">
                            <template v-slot:label>
                                <div>
                                    <a
                                            target="_blank"
                                            style="text-decoration: none;"
                                            href="https://simiaroom.com/%D8%B4%D8%B1%D8%A7%DB%8C%D8%B7-%D8%B6%D9%88%D8%A7%D8%A8%D8%B7-%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87/"
                                            @click.stop
                                    >
                                        شرایط و ضوابط
                                    </a>
                                    <!--<v-tooltip top>-->
                                    <!---->
                                    <!--<span>در یک صفحه جدید باز می شود.</span>-->
                                    <!--</v-tooltip>-->
                                    را خوانده و میپذیرم.
                                </div>
                            </template>
                        </v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-text v-show="registerErr.show" class="red--text text-center">{{ registerErr.msg }}</v-card-text>
            </v-window-item>

        </v-window>

        <v-divider></v-divider>

        <v-card-actions>
            <v-btn
                    :disabled="step === 1"
                    text
                    @click="back"
            >

                {{$vuetify.lang.t('$vuetify.backLabel')}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                    :disabled="step === 3"
                    color="primary"
                    depressed
                    @click="register"
            >
                {{$vuetify.lang.t('$vuetify.nextLabel')}}
            </v-btn>
        </v-card-actions>
    </div>
    <!--</v-card>-->
</template>
<script>
    /*eslint-disable*/
    import {parsePhoneNumberFromString, AsYouType} from 'libphonenumber-js'
    import {EventBus} from "@/event-bus";
    // import Tracker from '../tracker';

    export default {
        data() {
            return {
                registered_on: new Date(),
                inviter: null,
                privacyPolicy: false,
                phone: '',

                // email: "",
                password: "",
                confirmPassword: "",
                emailRules: [
                    v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
                    v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
                ],

                showPass: false,
                showConfirmPass: false,
                registerErr: {
                    show: false,
                    msg: '',
                },
                mutableEmail: '',
            }
        },

        props: {
            email: String,

            step: {
                type: Number,
                default: 1
            },
            // inviter: {
            //     default: null
            // }
        },

        created() {
            this.mutableEmail = this.email;
            this.inviter = this.$store.getters.getInviter;
        },

        watch: {
            phone() {

                if (this.phone.startsWith('00')) {
                    this.phone = this.phone.substr(2);
                    this.phone = '+' + this.phone;
                }

                if (this.phone.startsWith('098')) {
                    this.phone = this.phone.substr(1);
                    this.phone = '+' + this.phone;
                }

                if (this.phone.startsWith('09')) {
                    this.phone = this.phone.substr(1);
                    this.phone = '+98' + this.phone;
                }

                this.phone = new AsYouType().input(this.phone);
            }
        },
        computed: {
            passwordRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
                    v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
                ];
            },
            passwordConfirmRules() {
                return [
                    v => !!v || 'پر کردن فیلد تکرار کلمه عبور الزامیست',
                    v => (v === this.password) || 'کلمه عبور و تکرار آن یکسان نمی باشند'
                ];
            },
            currentTitle() {
                switch (this.step) {
                    case 1:
                        return this.$vuetify.lang.t('$vuetify.signUpLabel')
                    case 2:
                        return this.$vuetify.lang.t('$vuetify.setAPassword')
                    default:
                        return 'Account created'
                }
            },
        },
        methods: {
            register() {
                const phoneNumber = parsePhoneNumberFromString(this.phone.replace(' ', ''));

                if (this.step == 1) {
                    if (this.$refs.registerForm.validate()) {
                        this.step++
                    }
                } else {

                    if (phoneNumber) {

                        if (!phoneNumber.isValid()) {

                            EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.signupComponent.phoneNumberError'));
                            return;
                        }

                    } else {
                        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.signupComponent.phoneNumberError'));

                        return;

                    }

                    if (!this.privacyPolicy) {
                        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.signupComponent.acceptTerms'));
                        return;
                    }
                    if (this.$refs.signupform.validate()) {
                        if (this.confirmPassword === this.password) {

                            let newUser = {
                                email: this.mutableEmail,
                                password: this.password,
                                password_confirmation: this.confirmPassword,
                                mobile: phoneNumber.number,
                            };

                            if (this.inviter != null) {
                                newUser.inviter = this.inviter;
                            }
                            // if(this.$router.currentRoute.name == 'preorder') localStorage.setItem('preorder', this.$route.params.orderId);

                            this.$store.dispatch('register', newUser).then((data) => {
                                window.webengage.user.login(data.data.userId);
                                setTimeout(() => {
                                    window.webengage.user.setAttribute("registered_on", this.registered_on);
                                    window.webengage.user.setAttribute("we_email", this.mutableEmail);
                                    window.webengage.user.setAttribute("we_phone", phoneNumber.number);
                                    window.webengage.track('registered_on', {
                                        date: this.registered_on
                                    });
                                },100);


                                this.$store.dispatch('removeInviter');
                                EventBus.$emit('notify', 'green', 6000, this.$t('$vuetify.Notify.successfully'), 'ga_signup');
                                this.$emit('successfullyRegistered');
                                if (['ypreorder','preorder', 'xpreorder', 'mergePreorder','Langxpreorder','Langypreorder','Langonboarding'].includes(this.$router.currentRoute.name) ) {
                                    this.$emit('userLoggedInAfterRegister', newUser);

                                } else {
                                    this.$store.commit('setSnackbar', {show: true, type: 'checkEmail'});
                                }
                                // new Tracker("REGISTRATION", data.data.userId).dispatch()
                            }).catch((err) => {
                                console.log('sign up err', err.response.status);
                                if (err.response.status) {
                                    this.registerErr = {
                                        show: true,
                                        msg: this.$t('$vuetify.Notify.signupComponent.emailError')
                                    }
                                }
                                // else if (500 <= err.response.s < 600) {
                                //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
                                // }
                            })
                        } else if (this.confirmPassword !== this.password) {
                            this.registerErr = {
                                show: true,
                                msg: this.$t('$vuetify.Notify.signupComponent.passwordError'),
                            }
                        }
                    }
                }
            },
            back() {
                this.step--;
                this.registerErr = {
                    show: false,
                    msg: ''
                }
            }
        },
    }
</script>
<style>

</style>