<template>
    <v-window v-model="window" :reverse="this.$store.getters.isRTL">
        <v-window-item :value="1">

            <v-card-title class="title font-weight-regular justify-space-between">
                <span>{{ $vuetify.lang.t('$vuetify.loginLabel')}}</span>
            </v-card-title>

            <v-form class="pa-3" ref="loginForm" @keyup.native.enter="login">

                <v-card-text>
                    <v-text-field
                            v-model="mutableEmail"
                            type="text"
                            name="input-10-1"
                            :label="$vuetify.lang.t('$vuetify.emailLabel')"
                            required
                            :rules="emailRules"
                    >
                    </v-text-field>
                    <v-text-field
                            v-model="password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            :label="$vuetify.lang.t('$vuetify.passwordLabel')"
                            class="input-group--focused"
                            @click:append="showPassword = !showPassword"
                            required
                            :rules="passwordRules"
                    >

                    </v-text-field>
                </v-card-text>
                <v-card-text v-show="loginErr.show" class="red--text text-center">{{ loginErr.msg }}</v-card-text>
                <v-divider class=""></v-divider>

                <v-card-actions>
                    <v-btn color="primary" @click="login" :disabled="loginDisabled">
                        {{$vuetify.lang.t('$vuetify.loginLabel')}}
                    </v-btn>
                    <v-btn color="accent" text @click="window=2">{{$vuetify.lang.t('$vuetify.forgotPasswordText')}}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-window-item>
        <v-window-item :value="2">

            <v-card-title class="title font-weight-regular justify-space-between">
                <span>{{ $vuetify.lang.t('$vuetify.accountRecovery')}}</span>
            </v-card-title>

            <v-card-text>
                {{$vuetify.lang.t('$vuetify.accountRecoveryCaption')}}
            </v-card-text>
            <v-form ref="resetPassword">
                <v-text-field
                        @keydown.enter.prevent='resetPassword'
                        v-model="emailForResetPassword"
                        type="text"
                        :label="$vuetify.lang.t('$vuetify.emailLabel')"
                        :rules="emailRules"
                        @submit.stop="resetPassword"
                >
                </v-text-field>
                <v-card-text v-show="checkEmail" class="green--text text-center">ایمیل خود را چک کنید</v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" @click="window=1" text>{{$vuetify.lang.t('$vuetify.backLabel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="resetPassword">{{$vuetify.lang.t('$vuetify.nextLabel')}}</v-btn>

                </v-card-actions>
            </v-form>
        </v-window-item>
    </v-window>


</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "@/event-bus";

    export default {
        data() {
            return {
                showPassword: false,
                // email: "",
                mutableEmail: '',
                emailForResetPassword: '',
                checkEmail: false,
                // emailRules: [],
                password: "",
                errorMessages: '',
                name: null,
                address: null,
                city: null,
                state: null,
                zip: null,
                country: null,
                formHasErrors: false,
                window: 1,
                loginErr: {
                    show: false,
                    msg: '',
                },
                loginDisabled: false,
                registerDisabled: false
            }
        },
        props: {
            email: String
        },
        watch: {
            name() {
                this.errorMessages = ''
            },
        },
        computed: {
            passwordRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
                    v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
                ];
            },
            emailRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
                    v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
                ];
            }
        },
        created() {
            this.$store.commit('setLoading', false);
            this.mutableEmail = this.email;
        },
        methods: {
            addressCheck() {
                this.errorMessages = this.address && !this.name
                    ? 'Hey! I\'m required'
                    : ''

                return true
            },
            resetForm() {
                this.errorMessages = []
                this.formHasErrors = false

                Object.keys(this.form).forEach(f => {
                    this.$refs[f].reset()
                })
            },
            resetPassword() {
                if (this.$refs.resetPassword.validate()) {
                    this.$store.dispatch('resetPassword', {email: this.emailForResetPassword}).then((data) => {

                        if (data.type == 'SUCCESS') {
                            this.checkEmail = true;
                        } else {
                            this.checkEmail = false;
                        }
                    })
                }
            },
            login() {

                const user = {
                    email: this.mutableEmail,
                    password: this.password
                };

                if (this.$refs.loginForm.validate()) {
                    this.loginDisabled = true;

                    this.$store.dispatch("login", user).then((resp) => {

                        this.$store.dispatch('getUserInfo').then(() => {
                            //WebEngage
                            window.webengage.user.login(this.$store.getters.getUserInfo.id);
                            this.$store.commit('setLoggedIn');
                          EventBus.$emit('notify', 'green', 3000, this.$t('$vuetify.Notify.loginSuccess'),'ga_login');
                            EventBus.$emit('userLoggedIn');
                            let notRedirect = ['preorder','expresssession','preorder','xpreorder','ypreorder','Langxpreorder','Langypreorder','Langonboarding',]
                            if (!notRedirect.includes(this.$router.currentRoute.name) ) this.$router.push({name: 'dashboard'});
                        })
                    }).catch((err) => {
                        // console.log('err', err.response.data);
                        // if (err.response.data.statusCode === 401 || err.response.data.status === 400) {
                        //     this.loginErr = {
                        //         show: true,
                        //         msg: 'نام کاربری یا کلمه عبور اشتباه است',
                        //     }
                        // } else if (500 <= err.response.data.statusCode < 600) {
                        //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
                        // }
                    }).finally(() => {
                        this.loginDisabled = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>