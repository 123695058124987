<template>
    <div>
        <v-list v-if="showNavIcon" id="defaultNavIconItems">
            <v-list-item>
                <v-list-item-content class="py-0">
                    <v-btn text :to="{name:'login',params:{lang:$route.params.lang}}">{{$vuetify.lang.t('$vuetify.navHomeLabel')}}
                    </v-btn>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content class="py-0">
                    <v-btn text :to="{name:'login',params:{lang:$route.params.lang}}">
                        {{$vuetify.lang.t('$vuetify.navLoginSignupLabel')}}
                    </v-btn>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        computed: {
            showNavIcon() {
                // return true;
                return this.$vuetify.breakpoint.mdAndDown;
            },
            showTopMenu() {
                return this.$vuetify.breakpoint.mdAndUp;
            }
        }
    }
</script>

<style>
    #defaultNavIconItems .v-btn__content {
        justify-content: right;
    }
</style>