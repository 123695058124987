<template>
  <v-card class="pa-0">
    <v-card-title class="pt-4">
      <h3 class="card-title pmd-title-separator">{{ $t('$vuetify.factorsPage.PaymentType[2]')}}</h3>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-window v-model="offlinePaymentWindow">
        <v-window-item :value="1">
          <v-card class="elevation-0">
            <v-card-text>
              <div>{{$t('$vuetify.offlinePayment.title')}}</div>
              <v-list dense>

                <v-list-item class="outlined-warning" v-for="(method) in offlineCartCurrencyMethods"
                             :key="method.id"
                             @click="chooseOfflineMethod(method)">
                  <v-list-item-content class="orange--text">{{ $vuetify.rtl  ? method.title : method.en_title }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded @click="$emit('close')" class="red white--text">{{$t('$vuetify.offlinePayment.close')}}</v-btn>
            </v-card-actions>
          </v-card>

        </v-window-item>
        <v-window-item :value="2">
          <v-card class="elevation-0">
            <v-card-title v-if="paymentMethod!=null">{{$t('$vuetify.offlinePayment.action')}} {{ $vuetify.rtl ? paymentMethod.title : paymentMethod.en_title }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="addOfflinePaymentForm">
                <v-row v-if="paymentMethod.image!=null">
                  <v-col cols="12">
                    <v-card>
                      <v-img :src="paymentMethod.image"></v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-alert class="purple-light px-1 top-alert" dense>
                      <ul class="white--text" :class="$vuetify.rtl ? 'text-right' : 'text-left'">
                        <li class="ma-2 mr-4">
                          {{$t('$vuetify.offlinePayment.desc')}}
                        </li>
                      </ul>
                    </v-alert>
                    <!--<v-col v-if="getCart.currency !== 'rial' && paymentMethod.code !== 'USD'"-->
                    <!--class="text-center">-->
                    <!--برای تبدیل مبلغ به نرخ ارز {{paymentMethod.code}} از طریق <a-->
                    <!--:href="`${convertLink}`" target="_blank">این لینک</a> اقدام نمایید.-->
                    <!--</v-col>-->
                  </v-col>
                </v-row>
                <v-row>

                  <v-col cols="12" md="4" lg="4" sm="6">
                    <v-text-field
                        outlined
                        :label="$t('$vuetify.assignconsult.code')"
                        v-model="payment.transaction_code"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" lg="4" sm="6">
                    <v-text-field
                        v-if="convertedCart!=null"
                        class="mx-3"
                        :value="convertedCart.remaining_amount"
                        :label="` ${$t('$vuetify.CartComponent.invoiceItems.finalPrice')}`"
                        readonly
                        disabled
                        outlined
                    >
                      <template v-slot:append>
                                                <span class="black--text">
                                                    {{ $t(resolveCurrency(convertedCart.currency)) }}
                                                </span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" lg="4" sm="6">
                    <!--<v-dialog-->
                    <!--ref="dialog"-->
                    <!--v-model="modal"-->
                    <!--:return-value.sync="payment.date"-->
                    <!--persistent-->

                    <!--width="290px"-->
                    <!--&gt;-->
                    <!--<template v-slot:activator="{ on, attrs }">-->
                    <!--<v-text-field-->
                    <!--outlined-->
                    <!--v-model="payment.date"-->
                    <!--label="تاریخ پرداخت"-->
                    <!--:rules="requiredRules"-->
                    <!--prepend-icon="event"-->
                    <!--v-bind="attrs"-->
                    <!--readonly-->
                    <!--v-on="on"-->
                    <!--&gt;</v-text-field>-->
                    <!--</template>-->
                    <!--<v-date-picker v-model="payment.date" scrollable>-->
                    <!--<v-spacer></v-spacer>-->
                    <!--<v-btn text color="primary" @click="modal = false">بستن</v-btn>-->
                    <!--<v-btn text color="primary" @click="$refs.dialog.save(payment.date)">-->
                    <!--تایید-->
                    <!--</v-btn>-->
                    <!--</v-date-picker>-->
                    <!--</v-dialog>-->

                    <!--</template>-->
                    <v-text-field
                        outlined
                        v-model="payment.date"
                        :label="$t('$vuetify.offlinePayment.paymentDate')"
                        :rules="requiredRules"
                        append-icon="event"

                        readonly
                        @click="modal=true"
                    ></v-text-field>

                    <AdminDatePicker @close="modal=false" :dialog="modal"
                                     v-model="payment.date"></AdminDatePicker>
                  </v-col>
                  <v-col cols="12" md="4" lg="4" sm="6">
                    <v-text-field
                        outlined
                        :label="$t('$vuetify.offlinePayment.currency')"
                        readonly
                        :value="$t(resolveCurrency(payment.origin_currency))"
                        :rules="requiredRules"
                    >

                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" lg="4" sm="6">
                    <!--<v-text-field-->
                    <!--outlined-->
                    <!--label="مبلغ پرداختی"-->
                    <!--v-model="payment.origin_amount"-->
                    <!--:rules="requiredRules"-->
                    <!--hint="مبلغ پرداختی شما در ارز مورد نظر"-->
                    <!--&gt;-->
                    <!--</v-text-field>-->

                    <v-text-field
                        v-if="isFocused"
                        outlined
                        :label="$t('$vuetify.offlinePayment.PaymentAmount')"
                        :rules="requiredRules"
                        @blur="isFocused=false"
                        v-model="payment.origin_amount"

                    >
                    </v-text-field>
                    <v-text-field
                        v-else-if="!isFocused"
                        outlined
                        :label="$t('$vuetify.offlinePayment.PaymentAmount')"
                        :rules="requiredRules"
                        @focus="isFocused=true"
                        :value="payment.origin_amount | toLocaleOrFixed"
                        style="font-weight: bold"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

              </v-form>
              <v-row>

              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn text small @click="offlinePaymentWindow = 1">
                <v-icon small class="mx-1 grey--text">{{$vuetify.rtl ? 'fas fa-long-arrow-alt-right' : 'fas fa-long-arrow-alt-left'}}</v-icon>
                {{$t('$vuetify.offlinePayment.return')}}

              </v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="$emit('close')" rounded class="red white--text">
                {{$t('$vuetify.offlinePayment.close')}}
              </v-btn>
              <v-btn text class="primary px-5" rounded @click="sendOfflineOrder">
                {{$t('$vuetify.offlinePayment.action')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card-text>
    <AlertsComponent></AlertsComponent>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
import AlertsComponent from '@/components/AlertsComponent';
import {EventBus} from "@/event-bus";
import AdminDatePicker from '@/components/AdminDatePicker';
import {resolveCurrency} from "../../utilities";

export default {
  components: {
    AlertsComponent,
    AdminDatePicker
  },
  props: {
    cart: {
      default: () => ({})
    },
    type: {
      default: null
    },
    show: {
      default: false
    },
    preorder: {
      default: false
    },
    coupon_code: {
      default: ''
    }
  },
  created() {
    this.getOfflinePaymentMethods();
  },
  methods: {
    chooseOfflineMethod(item) {

      // this.paymentMethod = item;
      // this.payment.payment_method_id = item.id;
      // this.payment.origin_currency = item.code;
      // this.offlinePaymentWindow = 2;

      this.paymentMethod = item;
      let payload = {
        currency: item.code
      };
      if (this.type == null) {
        this.$store.dispatch('userChangeCartCurrency', payload).then((resp) => {
          this.convertedCart = resp.data;
          this.payment.payment_method_id = item.id;
          this.payment.origin_currency = item.code;
          this.offlinePaymentWindow = 2;
        });
      } else {
        this.convertedCart = this.cart;
        this.payment.payment_method_id = item.id;
        this.payment.origin_currency = item.code;
        this.offlinePaymentWindow = 2;
      }
    },
    sendOfflineOrder() {

      if (this.$refs.addOfflinePaymentForm.validate()) {
        this.payment.couponCode = this.coupon_code;
        EventBus.$emit('setLoading', true);

        if (this.type == null) {
          this.$store.dispatch('saveOpenCartOfflineOrder', this.payment).then(() => {

            this.$store.dispatch('getCart');
            this.$emit('done');

          }).finally(() => {
            EventBus.$emit('setLoading', false);
            this.$router.push({name: 'clientFactors'});
          });
        } else {
          this.$store.dispatch('saveFactorOfflineOrder', {
            factorId: this.cart.id,
            payload: this.payment
          }).then(() => {

            this.$emit('done');

          }).finally(() => {
            EventBus.$emit('setLoading', false);
          });
        }


      }

      // if (this.$refs.addOfflinePaymentForm.validate()) {
      //     this.payment.couponCode = this.coupon_code;
      //     EventBus.$emit('setLoading', true);
      //     this.$store.dispatch('saveofflineorder', this.payment).then(() => {
      //
      //         this.$store.dispatch('getCart');
      //         this.$emit('done');
      //
      //     }).finally(() => {
      //         EventBus.$emit('setLoading', false);
      //     });
      // }

    },

    getOfflinePaymentMethods() {
      this.paymentMethodsLoading = true;
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('userGetOfflinePaymentMethods').then((resp) => {
        this.offlinePaymentMethods = resp.data;

      }).finally(() => {
        this.paymentMethodsLoading = false;
        EventBus.$emit('setLoading', false);
      })
    },

    resolveCurrency(input) {
      return resolveCurrency(input);
      // let result = '';
      // switch (input) {
      //     case 'rial':
      //         result = 'تومان';
      //         break;
      //     case 'dollar':
      //         result = 'دلار';
      //         break;
      //     case 'cad':
      //         result = 'دلار کانادا';
      //         break;
      //     case 'aud':
      //         result = 'دلار استرالیا';
      //         break;
      //     case 'euro':
      //         result = 'یورو';
      //         break;
      //     case 'gbp':
      //         result = 'پوند';
      //         break;
      // }
      // return result;
    }
  },
  computed: {
    offlineCartCurrencyMethods() {

      if (this.type != null) {
        return this.offlinePaymentMethods.filter((item) => item.code == this.cart.currency);
      } else {
        return this.offlinePaymentMethods.filter((item) => item.currency == this.cart.currency);
      }
      // return this.offlinePaymentMethods.filter((item) => item.currency == this.getCart.currency);
      // return this.offlinePaymentMethods;
    },
    convertLink() {
      let baseUrl = 'https://www.xe.com/currencyconverter/convert/?';

      return baseUrl + `Amount=${this.getCart.finalPrice}&From=${this.getCart.currency}&To=${this.paymentMethod.code}`;
    },
    ...mapGetters({
      // getCart:   'getCart',
      currencies: 'getCurrencies'
    }),
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  filters: {
    toLocaleOrFixed(xx) {
      return Number(xx).toLocaleString();
    },

  },
  data() {
    return {
      convertedCart: null,
      isFocused: false,
      paymentMethod: {},
      modal: false,
      // currencies: [
      //     {title: 'دلار', value: 'dollar'},
      //     {title: 'تومان', value: 'rial'}
      // ],
      paymentMethodsLoading: false,
      offlinePaymentMethods: [],

      payment: {
        date: null,
        couponCode: null,
        transaction_code: '',
        payment_method_id: null,
        origin_amount: null,
        origin_currency: null,
      },
      offlineMethods: [],
      offlinePaymentWindow: 1,
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.offlinePaymentWindow = 1;
      }
    }
  }
}
</script>

<style scoped>

</style>
