<template>
  <v-app>
    <v-snackbar
        :id="snackbarId"
        v-model="snackbar.visibility"
        :color="snackbar.color"
        :bottom="snackbar.position === 'bottom'"
        :timeout="snackbar.timeout"
        :top="snackbar.position === 'top'"
    >
      {{ snackbar.message }}
      <v-btn
          color="primary--text"
          @click="snackbar.visibility = false"
          fab
          small
      >
        <v-icon x-small color="red">fas fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-dialog max-width="500" v-model="showExitIntent">
      <v-card>
        <div class="text-center title pt-3">
          <span>
              {{ $t('$vuetify.invoicePayable.title') }}
          </span>
        </div>
        <v-card-text class="mt-3 text-center">
          {{ $t('$vuetify.invoicePayable.description[0]') }}
          <span v-if="currency == 'dollar'">
          {{ $t('$vuetify.invoicePayable.description[1]') }}
          </span>
          <span v-else>
           {{ $t('$vuetify.invoicePayable.description[2]') }}
          </span>
          {{ $t('$vuetify.invoicePayable.description[3]') }}
        </v-card-text>
        <v-card-actions class="text-center">
          <v-btn class="mx-auto blue white--text mb-3" depressed @click="showExitIntent=false" id="back-to-cart">
            {{ $t('$vuetify.invoicePayable.action') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="!loggedIn">
      <v-fade-transition>
        <LoginRegisterModalPage :type="'separate'" :defaultWindow="2"></LoginRegisterModalPage>
      </v-fade-transition>
    </template>
    <template v-else>
      <PreorderNavbar :isSimia="true"></PreorderNavbar>
      <v-content>
        <v-container align="center" justify="center">
<!--          <v-fade-transition>-->
<!--            <div v-if="loading">-->
<!--              <v-dialog v-model="localTrue" max-width="500" persistent>-->
<!--                <v-card>-->
<!--                  <v-card-text class="pt-5">-->
<!--                    <v-progress-linear-->
<!--                        active-->
<!--                        indeterminate-->
<!--                        query-->
<!--                        rounded-->
<!--                        stream-->
<!--                    ></v-progress-linear>-->
<!--                  </v-card-text>-->
<!--                </v-card>-->
<!--              </v-dialog>-->
<!--            </div>-->
<!--          </v-fade-transition>-->

          <!--          <v-alert type="warning" dense v-if="currency=='dollar'">-->
          <!--            توجه: اگر از ایران سفارش خود را ثبت می کنید، پیش از ثبت سفارش از خاموش بودن-->
          <!--            فیلترشکن خود اطمینان داشته باشید.-->
          <!--          </v-alert>-->
          <PayableFactorAlert :preorder="true"></PayableFactorAlert>
          <!--          <v-row justify="center" align="center" dense>-->
          <!--            <v-col cols="12">-->
          <!--              <v-card class="elevation-1">-->
          <!--                <v-card-title class="font-weight-bold ">-->
          <!--                  <span>پرداخت و رزرو وقت مشاوره</span>-->
          <!--                </v-card-title>-->

          <!--                <v-divider></v-divider>-->
          <!--                <v-card-text>-->
          <!--                  <div dense>-->
          <!--                    <p class="pa-0 ma-0 pr-4">-->
          <!--                      <v-list-item-icon class="mx-1">-->

          <!--                        <v-icon x-small> fas fa-circle</v-icon>-->
          <!--                      </v-list-item-icon>-->

          <!--                      تمامی جلسات مشاوره آنلاین بصورت ارتباط تصویری امن می باشد. زمان هر جلسه-->
          <!--                      مشاوره آنلاین ۴۵دقیقه است.-->
          <!--                      <template v-if="isSimiaroom"> پس از پرداخت و ثبت سفارش جهت رزرو وقت، با شما-->
          <!--                        تماس خواهیم گرفت.-->
          <!--                      </template>-->

          <!--                    </p>-->
          <!--                    <p v-if="isSimiaroom" class="pa-0 ma-0 pr-4">-->
          <!--                      <v-list-item-icon class="mx-1">-->
          <!--                        <v-icon x-small> fas fa-circle</v-icon>-->
          <!--                      </v-list-item-icon>-->

          <!--                      مشاور شما توسط تیم تخصصی سیمیآروم و با توجه به موضوع مشاوره شما انتخاب می-->
          <!--                      شود. بنابراین پس از پرداخت، موضوع مشاوره تان را بصورت واضح وارد نمایید.-->

          <!--                    </p>-->

          <!--                    <p v-if="isSimiaroom" class="pa-0 ma-0 pr-4">-->
          <!--                      <v-list-item-icon class="mx-1">-->
          <!--                        <v-icon x-small> fas fa-circle</v-icon>-->
          <!--                      </v-list-item-icon>-->
          <!--                      جهت اطلاع از قیمت و رزرو وقت با روانپزشکان مجموعه (-->
          <!--                      <a class="mx-1 doctor-links" target="_blank"-->
          <!--                         href="https://simiaroom.com/counselor/%d8%af%da%a9%d8%aa%d8%b1-%d9%85%d9%87%d8%af%db%8c-%d8%a8%db%8c%d9%86%d8%a7/">-->
          <!--                        دکتر بینا </a>-->
          <!--                      ،-->
          <!--                      <a class="mx-1 doctor-links" target="_blank"-->
          <!--                         href="https://simiaroom.com/counselor/%d8%af%da%a9%d8%aa%d8%b1-%d8%b3%d8%a7%d9%85%d8%b1%d9%86%d8%af-%d8%b3%d9%84%db%8c%d9%85%db%8c/">-->
          <!--                        دکتر سلیمی </a>-->
          <!--                      ،-->
          <!--                      <a class="mx-1 doctor-links" target="_blank"-->
          <!--                         href="https://simiaroom.com/counselor/%d8%af%da%a9%d8%aa%d8%b1-%d8%ac%d8%b9%d9%81%d8%b1-%d8%a8%d9%88%d8%a7%d9%84%d9%87%d8%b1%db%8c/">-->
          <!--                        دکتر بوالهری </a>-->
          <!--                      و-->
          <!--                      <a class="mx-1 doctor-links" target="_blank"-->
          <!--                         href="https://simiaroom.com/counselor/%D8%AF%DA%A9%D8%AA%D8%B1-%D8%AD%D9%85%DB%8C%D8%AF%D8%B1%D8%B6%D8%A7-%D8%B1%D8%AD%D9%85%D8%A7%D9%86%DB%8C%D8%A7%D9%86/">-->
          <!--                        دکتر حمیدرضا رحمانیان </a>-->
          <!--                      ) از صفحه ی مربوط به این متخصصان اقدام نمایید.-->
          <!--                    </p>-->


          <!--                    <p v-if="currency=='dollar'" class="pa-0 ma-0 pr-4">-->
          <!--                      <v-list-item-icon class="mx-1 ">-->
          <!--                        <v-icon x-small class="green&#45;&#45;text"> fas fa-circle</v-icon>-->
          <!--                      </v-list-item-icon>-->

          <!--                      امکان ارائه ی تاییدیه ی پرداخت به موسسات بیمه برای دریافت هزینه ی جلسات-->
          <!--                      مشاوره (ایرانیان خارج از کشور)-->

          <!--                    </p>-->
          <!--                    <p v-if="isSimiaroom" class="pa-0 ma-0 pr-4">-->
          <!--                      <v-list-item-icon class="mx-1">-->
          <!--                        <v-icon x-small class="green&#45;&#45;text"> fas fa-circle</v-icon>-->
          <!--                      </v-list-i  tem-icon>-->

          <!--                      خبر خوب اینکه، برای اولین جلسه، با وارد کردن کد تخفیف firstorder میتوانید-->
          <!--                      سفارشتون رو با {{ discountText }} تخفیف پرداخت کنید.-->

          <!--                    </p>-->
          <!--                  </div>-->
          <!--                </v-card-text>-->
          <!--              </v-card>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <WizardStepIndicator :step="7" class="mt-2 mb-7"></WizardStepIndicator>
          <v-row justify="center" align="center" dense>

            <v-col cols="12" :lg="loggedIn? 12 : 8">
              <v-card class="pb-2 pa-0 elevation-0">
                <CartComponent
                    @finishedSendingOrder="sendingOrder = false"
                    discount="true"
                    price="true"
                    preorder="true"
                    showLoading="false"
                    :preorderCoupon="couponCode"
                ></CartComponent>
              </v-card>
            </v-col>
            <v-col cols="12" sm="8" md="6" lg="4">
              <v-card class="pa-3" v-if="!loggedIn">
                <!--<template v-if="action == null">-->
                <!--<v-card class="elevation-0" :loading="checkingEmail">-->
                <!--<v-card-title>ایمیل خود را وارد کنید</v-card-title>-->
                <!--<v-form ref="registerForm" @submit.prevent="checkUserStatus">-->
                <!--<v-card-text>-->
                <!--<v-text-field-->
                <!--v-model="email"-->
                <!--outlined-->
                <!--type="text"-->
                <!--label="ایمیل"-->
                <!--required-->
                <!--:rules="emailRules"-->
                <!--ref="signUpLoginForm"-->
                <!--&gt;-->
                <!--</v-text-field>-->
                <!--</v-card-text>-->
                <!--</v-form>-->
                <!--<v-card-actions class="justify-center mb-3">-->
                <!--<v-btn color="primary" @click.stop.prevent="checkUserStatus"-->
                <!--:disabled="checkingEmail">بررسی ایمیل-->
                <!--</v-btn>-->
                <!--</v-card-actions>-->
                <!--</v-card>-->
                <!--</template>-->
                <!--<template-->
                <!--v-else-if="action != null"-->
                <!--&gt;-->

                <template
                >

                  <v-tabs centered
                          v-model="tab"
                  >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab key="1" ref="loginTab" href="#login">
                      {{ $vuetify.lang.t('$vuetify.loginLabel') }}
                    </v-tab>
                    <v-tab key="2" ref="signUpTab" href="#register">
                      {{ $vuetify.lang.t('$vuetify.signUpLabel') }}
                    </v-tab>

                    <v-tab-item
                        :value="'login'"
                    >
                      <v-card
                          flat
                          tile
                      >

                        <LoginComponent :email="email"></LoginComponent>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :value="'register'"
                    >
                      <v-card
                          flat
                          tile
                      >
                        <SignupComponent @userLoggedInAfterRegister="userLoggedIn"
                                         :email="email"></SignupComponent>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </template>

              </v-card>
              <!--<v-card -->
              <!--class="secondary&#45;&#45;text d-flex justify-center align-center pa-5">-->
              <!--شما با موفقیت وارد شدید-->
              <!--</v-card>-->
            </v-col>

          </v-row>
          <br>
          <p class="card-title pmd-title-separator">اطلاعات بیشتر</p>
          <v-row>
            <v-col cols="12" lg="8">
              <div class=" px-2 py-2">
                <div class="d-flex  my-2 descItemText " v-for="(item,i) in descItem" :key="i">
                  <div>
                    <v-icon style="height: 50px;" size="10" color="grey" class="ml-3">mdi-circle</v-icon>
                    {{ item.text }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="whatsappArea text-center">
                <p class="text-bold text-center mt-8">اگر سوالی دارید، از طریق واتساپ با ما درمیان بگذارید</p>
                <a href="https://wa.me/00989357686754" style="text-decoration: none" target="_blank">
                  <v-btn color="green" class="white--text whatsAppBtnGrowth" rounded>
                    <v-icon color="white" class="ml-2">mdi-whatsapp</v-icon>
                    ارتباط از طریق واتساپ
                  </v-btn>
                </a>
              </div>
            </v-col>
          </v-row>

          <br>
          <!--          <PreorderTestimonials></PreorderTestimonials>-->
          <!--          <v-divider class="grey lighten-4"></v-divider>-->
          <!--          <v-row justify="center" align="center" style="width: 90%" class="mx-auto">-->

          <!--            <v-col cols="6" lg="2" sm="4" md="3" class="text-center preorder-info ">-->
          <!--              <img :src="require('../assets/images/counseler.png')" class="preorder-info-img" alt="">-->
          <!--              <div class="">{{ $t('$vuetify.preOrderPage.options[0]') }}</div>-->
          <!--            </v-col>-->
          <!--            <v-col cols="6" lg="2" sm="4" md="3" class="text-center preorder-info ">-->
          <!--              <img :src="require('../assets/images/insurance.png')" class="preorder-info-img" alt="">-->
          <!--              <div class="">{{ $t('$vuetify.preOrderPage.options[1]') }}</div>-->
          <!--            </v-col>-->
          <!--            <v-col cols="6" lg="2" sm="4" md="3" class="text-center preorder-info ">-->
          <!--              <img :src="require('../assets/images/gateway.png')" class="preorder-info-img" alt="">-->
          <!--              <div class="">{{ $t('$vuetify.preOrderPage.options[2]') }}</div>-->
          <!--            </v-col>-->
          <!--            <v-col cols="6" lg="2" sm="4" md="3" class="text-center preorder-info ">-->
          <!--              <img :src="require('../assets/images/moneyguarantee.png')" class="preorder-info-img" alt="">-->
          <!--              <div class="">{{ $t('$vuetify.preOrderPage.options[3]') }}</div>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <br>
          <v-divider class="grey lighten-4 "></v-divider>
          <br>
          <br>
        </v-container>
        <PreOrderFooter :isSimiaroom="isSimiaroom"></PreOrderFooter>

      </v-content>
    </template>


  </v-app>
</template>

<script>

/*eslint-disable*/
import PreorderTestimonials from '@/components/PreorderTestimonials.vue';
import PreorderNavbar from '@/components/PreorderNavbar.vue';
import PreOrderFooter from '@/components/PreOrderFooter.vue';
import LoginRegisterModalPage from '@/components/LoginRegisterModalPage';
import WizardStepIndicator from "../components/wizard/WizardStepIndicator";
import LoginComponent from "./LoginComponent";
import SignupComponent from "./SignupComponent";
import CartComponent from "./cart/CartComponent";
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";

import {getCookie} from "../utilities";
import PayableFactorAlert from '@/components/PayableFactorAlert'

export default {
  components: {
    WizardStepIndicator,
    PreorderTestimonials,
    LoginRegisterModalPage,
    CartComponent,
    PreorderNavbar,
    PreOrderFooter,
    LoginComponent,
    SignupComponent,
    PayableFactorAlert

  },
  computed: {
    showExitIntent: {
      set: function (val) {
        if (this.loggedIn) {
          this.$store.commit('SET_EXIT_INTENT', val)
        }
      },
      get: function () {
        return this.getShowExitIntent && this.loggedIn && this.noPaymentsHasWallet
      }
    },
    discountText() {
      return this.currency == 'rial' ? '50,000 تومان' : '5 دلار';
    },
    ...mapGetters({
      'getShowExitIntent': 'getShowExitIntent',
      'loading': 'loading',
      'loggedIn': 'userIsLoggedIn',
      'platform': 'getPlatform',
      'currency': 'getUserCurrency',
      'snackbarId': 'getSnackbarId'
    })
  },
  data() {
    return {
      localTrue: true,
      noPaymentsHasWallet: null,
      // showExitIntent: true,
      loginModal: true,

      isSimiaroom: false,
      hasCouponCode: false,
      couponCode: null,
      showOfflinePaymentDialog: false,
      validating: false,
      checkingEmail: false,
      sendingOrder: false,
      snackbar: {
        visibility: false,
        color: 'info',
        position: 'bottom',
        timeout: 5000,
        mode: 'vertical',
        message: 'this is snackbar message',
      },
      emailRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ],
      action: null,
      state: null,

      window: 1,
      tab: 'register',
      email: '',
      descItem: [
        {text: 'جلسات در زمان دلخواه شما برنامه‌ریزی و برگزار می‌شوند.'},
        {text: 'می‌توانید در طول دوره مشاوره، مشاور خود را تغییر دهید.'},
        {text: 'وجه پرداختی شما در صورت عدم تمایل برای ادامه مشاوره، بازگشت داده می‌شود.'},
        {text: ' کارشناسان با تجربه سیمیاروم، مناسب‌ترین مشاور را طبق مساله شما معرفی می‌کنند.'},
      ],
    }
  },

  mounted() {
    document.addEventListener("mouseout", this.addExitIntent)
    if (this.$route.params.lang == undefined) {
      EventBus.$on('notify', this.showSnackbar);
    }
    if (this.loggedIn) {
      this.getPaidCartsCount();
    }
    // this.validatePackage();
  },
  created() {

    let inviter = this.getCookie('inviter');
    if (this.$route.query.hasOwnProperty('inviter')) {
      this.delete_cookie('inviter', '/', 'simiaroom.com');
      this.$store.dispatch('setInviter', this.$route.query.inviter);
    } else if (inviter != "") {
      this.$store.dispatch('setInviter', inviter);
    }


    this.$store.dispatch('getPlatformInfo');
    EventBus.$on('setLoading', this.setLoading);

    if (this.$route.query.coupon_code != undefined) {
      this.hasCouponCode = true;
      // this.couponCode = this.$route.query.coupon_code;
    }


    this.$store.dispatch('checkLogin').then((resp) => {
      if (resp) this.validatePackage();
    })

    if (window.origin.includes('simiaroom')) {
      this.isSimiaroom = true;
    } else {
      this.isSimiaroom = false;
    }

    // this.$store.dispatch('getPlatformInfo').then(() => {
    //     document.title = this.platform.name;
    // if (this.platform.name === 'simiaroom') {
    // this.isSimiaroom = true;
    // }
    // });
    // this.validatePackage();

    // if (this.loggedIn) {
    //     this.$store.dispatch('getCart');
    // }

  },
  beforeDestroy() {
    document.removeEventListener("mouseout", this.addExitIntent)
    EventBus.$off('setLoading', this.setLoading);
    EventBus.$off('notify', this.showSnackbar);
  },
  methods: {
    emptyCart() {
      this.$store.dispatch('emptyCart').then(() => {
        alert('done!')
      });
    },
    getPaidCartsCount() {
      this.$store.dispatch('getPaidCartsCount').then((resp) => {

        this.noPaymentsHasWallet = resp.data.cartsCount;
      })
    },
    addExitIntent(event) {
      if (!event.toElement && !event.relatedTarget) {
        if (this.showExitIntent == undefined)
          this.showExitIntent = true;
      }
    },
    sendParams() {
      if (this.$route.query.hasOwnProperty('purpose')) {
        this.$store.dispatch('surveyData', this.$route.query).then((resp) => {
        })
      }
    },
    sendWebengageAddToCart(data) {
      window.webengage.track('add_to_cart', data);
    },

    delete_cookie(name, path, domain) {
      if (this.getCookie(name) != "") {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setLoading(loading) {
      this.$store.commit('setLoading', loading);
    },
    showSnackbar(color = 'success', timeout = 5000, message = 'عملیات با موفقیت انجام شد.', snackbarId = null) {

      this.snackbar.visibility = true;
      this.snackbar.color = color;
      this.snackbar.position = 'bottom';
      this.snackbar.timeout = timeout;
      this.snackbar.mode = 'vertical';
      this.snackbar.message = message;

      if (snackbarId != null) {
        this.$store.commit('SET_SNACKBAR_ID', snackbarId)
      }

    },
    checkUserStatus() {
      if (this.$refs.registerForm.validate()) {
        this.checkingEmail = true;
        this.$store.dispatch('checkUserAvailability', {email: this.email}).then((resp) => {
          this.action = resp.data.action;
          this.tab = this.action;
        }).finally(() => {
          this.checkingEmail = false;
        });
      }
    },
    validatePackage() {
      // this.loading = true;
      EventBus.$emit('setLoading', true);
      let item = {
        id: this.$route.params.orderId,
        preorder: true
      };
      this.validating = true;

      this.$store.dispatch('addToCart', item).then((resp) => {
        let addedPackage = resp.data.package;
        let product = {
          'name': addedPackage.title,
          'id': addedPackage.id,
          'price': addedPackage.pivot.price,
          'brand': 'Simiaroom',
          'category': 'Counseling',
          'quantity': 1
        };

        this.sendWebengageAddToCart(product);

        this.$gtm.trackEvent({
          'event': 'eec.addToCart',
          'ecommerce': {
            'add': {
              'products': [product]
            }
          }
        });
        this.validating = false;

        if (this.hasCouponCode) {
          this.couponCode = this.$route.query.coupon_code;
        }
      }).catch(() => {
        this.validating = false;
        setTimeout(() => {
          this.$router.push({name: 'login', params: {lang: 'fa'}});
        }, 3000)

      }).finally(() => {
        this.validating = false;
        EventBus.$emit('setLoading', false);
        // this.loading = false;
      });

    },
    // sendOrder() {
    //     this.sendingOrder = true;
    //     EventBus.$emit('sendOrderEvent');
    // },
    userLoggedIn(newUser) {
      let user = {
        email: newUser.email,
        password: newUser.password
      };

      this.$store.dispatch("login", user).then(() => {
        this.$store.dispatch('getUserInfo').then(() => {
          this.$store.commit('setLoggedIn');
          EventBus.$emit('userLoggedIn');
        })
      })
    },
  },
  watch: {
    // showExitIntent(val) {
    //   if (!val) {
    //     this.$store.commit('SET_EXIT_INTENT', false)
    //   }
    // },
    loggedIn(newVal) {
      if (newVal) {
        this.validatePackage();
        this.getPaidCartsCount();
        this.sendParams()
      }
      // alert(this.loggedIn);
      // if(this.loggedIn) document.documentElement.style.overflow = 'scroll'
    }
  }
}
</script>

<style scoped>

.doctor-links {
  width: 100px;
}

.preorder-info-img {
  width: 40%;
}

.preorder-info {
  font-size: .9em;
}

.descItemText {
  font-size: 13px;
}

</style>