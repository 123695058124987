<template>
  <div class="d-flex step-indicator">
    <div class="bullet-holder" :class="{'active':step > 1}">
      <div class="step-bullets" ></div>
      <div class="title" :style="checkLang == 'fa' ? 'margin-right: -45px' : 'margin-left: -45px'">
        {{$t('$vuetify.onBoarding.wizardStepIndicator.info')}}
      </div>
    </div>
    <div class="mini-line " :class="{'passed':step>=2,'in-progress':step<2}">
<!--      <div :class="{'active-dot':step==2}"></div>-->
    </div>
    <div class="mini-line " :class="{'passed':step>=3,'in-progress':step<3}">
<!--      <div :class="{'active-dot':step==3}"></div>-->
    </div>
    <div class="mini-line " :class="{'passed':step>=4,'in-progress':step<4}">
<!--      <div :class="{'active-dot':step==4}"></div>-->
    </div>
    <div class="mini-line " :class="{'passed':step>=5,'in-progress':step<5}">
<!--      <div :class="{'active-dot':step==5}"></div>-->
    </div>
    <div class="bullet-holder" :class="{'active':step > 5}">
      <div class="step-bullets"></div>
      <div class="title" :style="checkLang == 'fa' ? 'margin-right: -45px' : 'margin-left: -45px'" >{{$t('$vuetify.onBoarding.wizardStepIndicator.register')}}</div>
    </div>
    <div class="line flex-grow-1" :class="{'passed':step>6}"></div>
    <div class="bullet-holder" :class="{'active':step == 7}">
      <div class="step-bullets"></div>
      <div class="title" :style="checkLang == 'fa' ? 'margin-right: -45px' : 'margin-left: -45px'" >{{$t('$vuetify.onBoarding.wizardStepIndicator.pay')}}</div>
    </div>
    <div class="line flex-grow-1"></div>
    <div class="bullet-holder">
      <div class="step-bullets"></div>
      <div class="title" :style="checkLang == 'fa' ? 'margin-right: -45px' : 'margin-left: -45px'">{{$t('$vuetify.onBoarding.wizardStepIndicator.selectConsulter')}}</div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    step: {
      default: 2
    }
  },
  computed:{
    checkLang(){
      return localStorage.getItem('local') == "fa" || localStorage.getItem('local') == undefined ? 'fa' : 'en';
    }
  }
}
</script>

<style scoped>
.step-indicator {
  width: 40%;
  justify-content: space-between !important;
  font-size: 24px;
  margin: 0 auto;
}

@media screen and (max-width: 470px) {
  .step-indicator {
    width: 55%;
  }
}

.bullet-holder {
  position: relative;
}

.line {
  height: 2px;
  margin-top: 5px;
  background-color: #b9b9b9;
  transition: all .3s linear;
}

.mini-line {
  height: 2px;
  margin-top: 5px;
  background-color: #b9b9b9;
  transition: all .3s linear;
  flex-grow: .25;
  position: relative;
}

.passed {
  background-color: #6d5aac!important;
}

.active-dot {
  transition: all .3s linear;
  display: block;
  content: ' ';
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #6d5aac;
  left: 0;
  top: -2px;
  border-radius: 10px;
}


.mini-line.current {
  background-color: #d1dcff;
}

.bullet-holder.active + .line {
  background-color: #d1dcff;
}

.step-bullets {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  transition: all .3s linear;
  background-color: #b9b9b9;
}

.bullet-holder.active .step-bullets {
  background-color: #6d5aac;
}

.bullet-holder .title {
  position: absolute;
  text-align: center;
  width: 100px;
  /*margin-right: -45px;*/
  color: #6d6d6d;
  font-size: .4em !important;
}

.bullet-holder.active .title {
  color: #6d5aac;
}

.in-progress {
  background-color: #d1dcff;
}
</style>