<template>
  <v-row align="center" justify="center">
    <v-col :cols="$vuetify.breakpoint.mdAndUp? 8:12">
      <hooper
          :centerMode="true"
          :rtl="true"
          :itemsToShow="itemToShow"
          :autoPlay="true"
          :playSpeed="15000"
          style="min-height:300px;"
          v-if="showSlider"
          :infiniteScroll="true"
          :wheel-control="false"
      >
        <slide>
          <v-card min-height="200" class="ma-1 pt-3 text-center mx-auto" elevation="0"
                  style="width: 90%;background-color:rgba(0,0,0,.01);">
            <v-card-text>
              <!--              <country-flag size="big" rounded :country="'US'"></country-flag>-->
              <div class="testimonial-flag" style="height: 52px;">
                <img :src="require('../assets/countryflags/usa.png')" alt="">
              </div>
              <div class="mt-2" style="font-weight: lighter">{{$t('$vuetify.preOrderPage.testimonial.America.name')}}</div>
              <div class="mt-4 testimonial-content">
                {{$t('$vuetify.preOrderPage.testimonial.America.content')}}
              </div>
            </v-card-text>
          </v-card>
        </slide>


        <slide>
          <v-card min-height="200" class="ma-1 pt-3 text-center mx-auto" elevation="0"
                  style="width: 90%;background-color:rgba(0,0,0,.01);">
            <v-card-text>
              <!--              <country-flag size="big" rounded :country="'DE'"></country-flag>-->
              <div class="testimonial-flag" style="height:58px;">
                <img :src="require('../assets/countryflags/germany.png')" alt="">
              </div>
              <div class="mt-2" style="font-weight: lighter">{{$t('$vuetify.preOrderPage.testimonial.germany.name')}}</div>
              <div class="mt-4 testimonial-content">
                {{$t('$vuetify.preOrderPage.testimonial.germany.content')}}
              </div>
            </v-card-text>
          </v-card>
        </slide>


        <slide>
          <v-card min-height="200" class="ma-1 pt-3 text-center mx-auto" elevation="0"
                  style="width: 90%;background-color:rgba(0,0,0,.01);">
            <v-card-text>
              <!--              <country-flag size="big" rounded :country="'SE'"></country-flag>-->
              <div class="testimonial-flag" style="height: 62px;">
                <img :src="require('../assets/countryflags/sweden.png')" alt="">
              </div>
              <div class="mt-2" style="font-weight: lighter">
                {{$t('$vuetify.preOrderPage.testimonial.Sweden.name')}}
              </div>
              <div class="mt-4 testimonial-content">
                {{$t('$vuetify.preOrderPage.testimonial.Sweden.content')}}
              </div>
            </v-card-text>
          </v-card>
        </slide>
        <slide>
          <v-card min-height="200" class="ma-1 pt-3 text-center mx-auto" elevation="0"
                  style="width: 90%;background-color:rgba(0,0,0,.01);">
            <v-card-text>
              <!--              <country-flag size="big" rounded :country="'SE'"></country-flag>-->
              <div class="testimonial-flag" style="height: 62px;">
                <img :src="require('../assets/countryflags/spain.png')" alt="">
              </div>
              <div class="mt-2" style="font-weight: lighter">
                {{$t('$vuetify.preOrderPage.testimonial.spain.name')}}
              </div>
              <div class="mt-4 testimonial-content">
                {{$t('$vuetify.preOrderPage.testimonial.spain.content')}}
              </div>
            </v-card-text>
          </v-card>
        </slide>
        <!--        <hooper-pagination style="background-color: rgba(0,0,0,.09);border-radius:15px;width:85px" slot="hooper-addons"></hooper-pagination>-->
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </v-col>
  </v-row>
</template>

<script>
/*eslint-disable*/
import {
  Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'App',
  computed: {
    itemToShow() {
      return this.$vuetify.breakpoint.mdAndUp ? 1 : 1;
    }
  },
  data() {
    return {
      showSlider: true
    }
  },
  components: {
    Hooper,
    Slide,
    // CountryFlag,
    HooperNavigation,
    HooperPagination
  },
  watch: {
    '$vuetify.breakpoint.mdAndUp'() {
      this.showSlider = false;
      this.$nextTick().then(() => {
        this.showSlider = true;
      })
    },

  }
};
</script>
<style>
.testimonial-content {
  line-height: 2em;
}

.hooper-next, .hooper-prev {
  opacity: .2 !important;
  transition: opacity .3s linear !important;
}

.hooper-next:hover, .hooper-prev:hover {
  opacity: 1 !important;
}

.testimonial-flag {
  border-radius: 5px;
  overflow: hidden;
  width: 100px;
  margin: 0 auto;
  box-shadow: 0 8px 15px -11px rgba(0, 0, 0, .6);
}

.testimonial-flag img {
  width: 100%;
}
</style>