<template>
  <v-card class="mt-3 ">
    <v-card-title>
      {{ $t('$vuetify.userWallet.giftCode.title') }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      {{ $t('$vuetify.userWallet.giftCode.subtitle') }}
      <v-form ref="giftCodeForm" @keydown.enter="applyGiftCode" @submit.prevent="applyGiftCode">
        <v-text-field
            outlined
            class="mt-2"
            rounded
            v-model="giftCode"
            :label="$t('$vuetify.userWallet.giftCode.placeHolder')"
            :error="validGiftCode==='invalid'"
            :success="validGiftCode==='valid'"
            :rules="requiredRules"
        >
          <template v-slot:append>
            <v-btn class="primary elevation-0" @click="applyGiftCode" rounded
                   style="top:-7px;"
            >
              {{$t('$vuetify.userWallet.giftCode.action') }}
            </v-btn>
          </template>
        </v-text-field>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {

  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  data() {
    return {
      giftCode: null,
      validGiftCode: null,
    }
  },
  methods: {
    applyGiftCode() {

      if (this.$refs.giftCodeForm.validate()) {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('userApplyGiftCode', {
          giftCode: this.giftCode
        }).then(() => {
          this.$store.dispatch('getUserInfo').then(() => {
            EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfully'));
            this.$emit('done');
          }).finally(() => {
            this.$emit('close');
            EventBus.$emit('setLoading', false);
          })
        }).catch(() => {
          this.$emit('close');
          EventBus.$emit('setLoading', false);
        })
      } else {
        console.log('damn');
      }

    }
  }
}
</script>

<style scoped>

</style>